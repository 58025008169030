<template>
  <div class="loginmodal">
    <div class="msgArt">
      <img src="@/assets/img/icon_airplane.svg" />
      <p>{{ $t('front.message.new') }}</p>
      <a @click="goPageByName('msg')">{{ $t('front.board.check') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewMessageMobile'
}
</script>

<style scoped>
.loginmodal {
  display: block !important;
}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
