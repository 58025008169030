<template>
  <div class="left_section" :class="{ active: $props.isActive }">
    <div class="left_info">
      <div class="right_section">
        <div class="title">
          <img src='@/assets/img/core/logo.png' style="width: 150px;" alt="" />
        </div>
        <div class="right_menu">
          <ul>
            <li>
              <div class="right_info_id">
                <i>
                  <img src='@/assets_mobile/img/winner_mobile/icon/member/gicon2.png?v=1682318327' />
                </i>
                <span class="info_id">{{ userData.memNick }}</span>
                <i class="cursor" @click="getGameCoin">
                  <img src="@/assets/img/core/reload.png" alt="" />
                </i>
              </div>
            </li>
            <li>
              <div class="right_info_grid">
                <i>
                  <img src="@/assets/img/core/won.png" alt="" />
                </i>
                <span>{{ thousand(userData.cashAmt ? userData.cashAmt.toString() : '0') }}</span>
              </div>
              <div class="right_info_grid">
                <i>
                  <img src="@/assets/img/core/point.png" alt="" />
                </i>
                <span>{{ thousand(userData.pointAmt ? userData.pointAmt.toString() : '0') }} P</span>
              </div>
            </li>
            <li>
              <a @click="openPointExchange()" class="myButton_02">{{ $t('front.header.pointConversion') }}</a>
              <a @click="goPageByName('info')" class="myButton_02">{{ $t('front.header.ChangingInfo') }}</a>
              <button class="myButton_02" @click="signOut">{{ $t('front.common.logout') }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="left_menu">
      <ul>
        <li>
          <a @click="goPageByName('casino')">
            <i>
              <img src="@/assets/img/core/casino_active.png" alt="" />
            </i>
            {{ $t('front.gameCategory.casino') }}
          </a>
          <a @click="goPageByName('slot')">
            <i>
              <img src="@/assets/img/core/slot_active.png" alt="" />
            </i>
            {{ $t('front.gameCategory.slot') }}
          </a>
        </li>
        <li>
          <router-link to="/sports/welcome">
            <i>
              <img src="@/assets/img/core/footBall_active.png" alt="" />
            </i>
            {{ $t('front.gameCategory.sports') }}
          </router-link>
          <a @click="goPageByName('minigame')">
            <i>
              <img src="@/assets/img/core/minigame_active.png" alt="" />
            </i>
            {{ $t('front.gameCategory.minigame') }}
          </a>
        </li>
      </ul>
    </div>

    <div class="left_menu bg">
      <ul>
        <li>
          <a @click="goPageByName('betList')">
            <i>
              <img src="@/assets/img/core/history_active.png" alt="" />
            </i>
            {{ $t('front.common.betHistory') }}
          </a>
        </li>
        <li>
          <a @click="goPageByName('qnaList')">
            <i>
              <img src="@/assets/img/core/edit_active.png" alt="" />
            </i>
            {{ $t('front.common.qna') }}
          </a>
          <a @click="goPageByName('msg')">
            <i>
              <img src="@/assets/img/core/message_active.png" alt="" />
            </i>
            쪽지
          </a>
        </li>
        <li>
          <a @click="goPageByName('noticeList')">
            <i>
              <img src="@/assets/img/core/notification_active.png" alt="" />
            </i>
            {{ $t('front.common.notice') }}
          </a>
          <a @click="goPageByName('point')">
            <i>
              <img src="@/assets/img/core/point_active.png" alt="" />
            </i>
            {{ $t('front.common.point') }}
          </a>
        </li>
        <li>
          <a @click="goPageByName('info')">
            <i>
              <img src="@/assets/img/core/mypage_active.png" alt="" />
            </i>
            {{ $t('front.common.mypage') }}
          </a>
        </li>
        <li>
          <a @click="goPageByName('charge')">
            <i>
              <img src="@/assets/img/core/deposit_active.png" alt="" />
            </i>
            {{ $t('front.common.MakeAdeposit') }}
          </a>
          <a @click="goPageByName('exchange')">
            <i>
              <img src="@/assets/img/core/withdraw_active.png" alt="" />
            </i>
            {{ $t('front.common.makeAwithdraw') }}
          </a>
        </li>
        <template v-if="siteOption">
          <template v-if="userData">
            <li v-if="siteOption.recommendYn === 'Y' && userData.recommendYn === 'Y'">
              <a @click="partnerConfirmOpen()">
                <i>
                  <img src="@/assets/img/winner_pc/icon/partner_active.png" alt="" />
                </i>
                {{ $t('front.mypage.exclusive') }}
              </a>
            </li>
          </template>
        </template>
        <template v-if="siteOption">
          <template v-if="userData">
            <li>
              <a href="#" class="telegram_border" v-if="siteOption.sns1">
                <i>
                  <img :src="loadSnsIcon(siteOption.sns1)" :alt="`${siteOption.sns1}: ${siteOption.sns3Id}`" style="width:24px;">
                </i>
                <template v-if="siteOption.sns1Id && userData">
                  {{ siteOption.sns1Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns1}`) }}
                </template>
              </a>
              <a href="#" class="mobile_border" v-if="siteOption.sns2">
                <i>
                  <img :src="loadSnsIcon(siteOption.sns2)" :alt="`${siteOption.sns2}: ${siteOption.sns2Id}`" style="width:24px;">
                </i>
                <template v-if="siteOption.sns2Id && userData">
                  {{ siteOption.sns2Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns2}`) }}
                </template>
              </a>
              <a href="#" class="mobile_border" v-if="siteOption.sns3">
                <i>
                  <img :src="loadSnsIcon(siteOption.sns3)" :alt="`${siteOption.sns3}: ${siteOption.sns3Id}`" style="width:24px;">
                </i>
                <template v-if="siteOption.sns3Id && userData">
                  {{ siteOption.sns3Id }}
                </template>
                <template v-else>
                  {{ $t(`front.common.${siteOption.sns3}`) }}
                </template>
              </a>
            </li>
          </template>
        </template>
      </ul>
      <!-- <div>
        <a href="http://wn-oo.com/index.php?f=mobile">
          <img src="@/assets_mobile/img/winner_mobile/go_pc.png">
        </a>
      </div> -->
    </div>

  </div>
  <div class="popup_mask" v-if="isPointExchageVisible">
    <!-- 포인트 전환 팝업 -->
    <div class="popup1">
      <div class="popup_inner">
        <div class="title">
          <span @click="closePointExchange()"></span>
        </div>
        <div class="signup_logo">
          <img src='@/assets/img/core/logo.png' />
        </div>
        <dl>
          <dt>{{ $t('front.cash.nav4') }}</dt>
          <dd>{{ thousand(currentPoint.toString()) }} P</dd>
        </dl>
        <dl>
          <dt>{{ $t('front.point.changePoint') }}</dt>
          <dd>
            <input class="money_input" type="text" pattern="[0-9.,]+" placeholder="숫자만 입력하세요" v-model="changePoint" />
          </dd>
        </dl>
        <div class="d-f">
          <button class="myButton_02" type="button" @click="setPoint('all')">{{ $t('front.cash.all') }}
          </button>
          <button class="myButton_02" type="button" @click="onSubmit">{{ $t('front.point.pointTrans') }}
          </button>
        </div>
        <!-- <RouterLink to="point" class="tx">마이페이지에서 포인트 전환내역확인하기</RouterLink> -->
      </div>
    </div>
  </div>
</template>

<script>
import { thousand } from '@/libs/utils'
import { setChangePoint } from '@/api/point'
import { mapState } from 'vuex'
import store from '@/store'

export default {
  name: 'NavigationLeft',
  props: ['isActive'],
  data() {
    return {
      currentPoint: 0,
      changePoint: '0',
      isPointExchageVisible: false
    }
  },
  computed: {
    ...mapState([
      'siteOption',
      'userData'
    ]),
    userLevelImage() {
      return require(`../../assets/img/winner_pc/member/icon_level_${this.userData.memLevel}.png`)
    },
    isUnReadMessage() {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    partnerConfirmOpen() {
      window.open('/partner')
    },
    loadSnsIcon(name) {
      try {
        return require(`@/assets/img/winner_pc/icon/${name}.png`)
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    getGameCoin() {
      store.dispatch('storeUserCoinAmt')
    },
    setPoint(value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }
      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit() {
      if (this.changePoint) {
        if (this.limitMinPoint > this.changePoint) {
          this.onCheck('front.point.limitPoint')
          return false
        }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '200') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            } else {
              let alertMessage = ''
              if(result.resultCode === 'C203') {
                alertMessage = this.$t(`api.${result.resultCode}`, { pointLimit: this.thousand(this.siteOption.pointChangeAmtLimit) })
              }else if(result.resultCode === 'C205') {
                alertMessage = this.$t(`api.${result.resultCode}`, { pointUnit: this.thousand(this.siteOption.pointChangeAmtUnit) })
              } else {
                alertMessage = this.$t(`api.${result.resultCode}`)
              }
              await this.onCheck(alertMessage)
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    },
    openPointExchange() {
      console.log('kkkkkk')
      this.isPointExchageVisible = true
      this.currentPoint = this.userData.pointAmt || 0
    },
    closePointExchange() {
      this.isPointExchageVisible = false
    }
  },
  watch: {
    $route: {
      handler() {
        this.isPointExchageVisible = false
      }
    },
    changePoint() {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    }
  }
}
</script>

<!-- <style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/navigation.css"></style>
<style scoped src="@/styles/header.css"></style> -->
