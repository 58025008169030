import httpClient from '@/libs/http-client'

export function bettingList(params) {
  const url = '/api/bet/betlist'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export function bettingDetail(params) {
  const url = '/api/bet/detail'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
