export const phishingGame = {
  name: 'phishingGame',
  path: '/phishinggame',
  meta: {
    layout: 'default',
    text: '피싱게임',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'kplayfishing',
      path: '/kplayfishing',
      meta: {
        text: '케이플레이피싱',
        img: require('@/assets/img/menu20.png'),
        gnbSub: true,
        game: true,
        gameName: 'fishing',
        class: 'fish',
        gameCode: 1000
      }
    }
  ]
}
