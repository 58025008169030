export const board = {
  name: 'board',
  path: '/board',
  component: () => import('@/views_mobile/member/board'),
  redirect: {
    name: 'noticelist'
  },
  meta: {
    layout: 'mobile-default',
    text: 'board',
    gnb: false,
    gnbMenu: 'board'
  },
  children: [
    {
      name: 'noticelist',
      path: '/board/list',
      component: () => import('@/views_mobile/member/board/list'),
      meta: {
        layout: 'mobile-default',
        text: '게시판',
        gnb: false,
        gnbMenu: 'board'
      }
    },
    {
      name: 'boardRead',
      path: '/board/read/:boardIdx',
      component: () => import('@/views_mobile/member/board/read'),
      meta: {
        layout: 'mobile-default',
        text: '게시판',
        gnb: false,
        gnbMenu: 'board'
      }
    },
    {
      name: 'boardWrite',
      path: '/board/write',
      component: () => import('@/views_mobile/member/board/write'),
      meta: {
        layout: 'mobile-default',
        text: '게시판',
        gnb: false,
        gnbMenu: 'board'
      }
    }
  ]
}
