export const stributor = {
  name: 'stributor',
  path: '/stributor',
  component: () => import('@/views/member/stributor'),
  meta: {
    layout: 'default',
    text: '총판페이지',
    gnb: false
  }
}
