<template>
  <transition name="fade">
    <new-message-mobile v-if="isUnReadMessage" />
  </transition>
  <transition name="fade">
    <signin-mobile v-if="isLoginOpen" @close="onCloseSignin" />
  </transition>
  <template v-if="type != 'empty'">
    <!-- <div class="fx">
      <ul class="fxhead">
        <li class="atten" :class="{ 'on': $route.meta.gnbMenu === 'charge' }"><a @click="goPageByName('charge')"><em></em><span>{{ $t('front.common.deposit') }}</span></a></li>
        <li class="notice" :class="{ 'on': $route.meta.gnbMenu === 'exchange' }"><a @click="goPageByName('exchange')"><em></em><span>{{ $t('front.common.withdrawal') }}</span></a></li>
        <li class="home" :class="{ 'on': $route.meta.gnbMenu === 'betList' }"><a @click="goPageByName('betList')"><em></em><span>{{ $t('front.mypage.betList') }}</span></a></li>
        <li class="ques" :class="{ 'on': $route.meta.gnbMenu === 'cs' }"><a @click="goPageByName('qnaList')"><em></em><span>{{ $t('front.common.cscenter') }}</span></a></li>
        <li class="my" :class="{ 'on': $route.meta.gnbMenu === 'myinfo' }"><a @click="goPageByName('myinfo')"><em></em><span>{{ $t('front.common.mypage') }}</span></a></li>
      </ul>
    </div> -->

    <header>
      <div class="noticeWrap">
        <div class="notice">
          <em>{{ $t('front.common.notices') }}</em>
          <!-- <span>{{$t('front.header.msg')}}</span> -->
          <Vue3Marquee :duration="10">
            <template v-if="marqueeList.length !== 0">
              <span v-for="(word, index) in marqueeList" :key="index" class="marquee-item">
                {{ word }}
              </span>
            </template>
            <span v-else>{{ $t('front.header.msg') }}</span>
          </Vue3Marquee>
        </div>
        <div class="langbtn" @click="openLang()">
          <button class="langdropbtn">
            <template v-if="$i18n.locale === 'ko'">
              <img src="@/assets_mobile/img/korea.png" /><span class="langsp"></span>
            </template>
            <template v-if="$i18n.locale === 'en'">
              <img src="@/assets/img/eng.png" /><span class="langsp"></span>
            </template>
            <template v-if="$i18n.locale === 'cn'">
              <img src="@/assets/img/cn.svg" /><span class="langsp"></span>
            </template>
            <template v-if="$i18n.locale === 'th'">
              <img src="@/assets/img/th.svg" /><span class="langsp"></span>
            </template>
            <template v-if="$i18n.locale === 'jp'">
              <img src="@/assets/img/jp.svg" /><span class="langsp"></span>
            </template>
            <template v-if="$i18n.locale === 'vn'">
              <img src="@/assets/img/vn.svg" /><span class="langsp"></span>
            </template>
            <img class="langbtnb" src="@/assets_mobile/img/langbtn.png" />
          </button>
          <div class="langdropdown" v-if="isOpenLang">
            <a href="#" :class="{ 'on': $i18n.locale === 'ko' }" @click="changeLocale('ko')"><img src="@/assets/img/kr.svg" /></a>
            <a href="#" :class="{ 'on': $i18n.locale === 'en' }" @click="changeLocale('en')"><img src="@/assets/img/us.svg" /></a>
            <a href="#" :class="{ 'on': $i18n.locale === 'cn' }" @click="changeLocale('cn')"><img src="@/assets/img/cn.svg" /></a>
            <a href="#" :class="{ 'on': $i18n.locale === 'th' }" @click="changeLocale('th')"><img src="@/assets/img/th.svg" /></a>
            <a href="#" :class="{ 'on': $i18n.locale === 'jp' }" @click="changeLocale('jp')"><img src="@/assets/img/jp.svg" /></a>
            <a href="#" :class="{ 'on': $i18n.locale === 'vn' }" @click="changeLocale('vn')"><img src="@/assets/img/vn.svg" /></a>
          </div>
        </div>
      </div>
      <div class="header">
        <div class="header-item ic_left_menu">
          <button @click="leftSectionToggle" v-if="isLoggedIn">
            <img src="@/assets/img/core/ham.png" alt="" />
          </button>
        </div>
        <div class="header-item">
          <a href="/" target="_top"><img src='@/assets/img/core/logo.png' /></a>
        </div>
        <div class="header-item ic_right_menu" :class="{ ic_member : isLoggedIn}">
          <template v-if="!isLoggedIn">
            <button href="" class="myButton_x_01" @click="onOpenSignin">{{ $t('front.gnb.login') }}</button>
            <button href="" class="myButton_x_01" @click="goPageByName('signup')">{{ $t('front.common.signup') }}</button>
          </template>
          <!-- <template v-else>
            <button @click="rightSectionToggle">
              <img src="@/assets_mobile/img/winner_mobile/icon/icon-settings_2.png" alt=""/>
            </button>
          </template> -->
        </div>
      </div>

      <!-- <div class="header">
        <h1 class="logo"><a @click="goPageByName('mobileMain')"><img src="@/assets/img/ace_logo.svg" /></a></h1>
        <div class="logininfowrap" v-if="userData">
          <ul class="mym">
            <li class="myc"><img src="@/assets_mobile/img/mycicon.png" /><span>{{ userData.cashAmt ? thousand(userData.cashAmt.toString()) : 0 }}</span></li>
            <li class="myp" @click="goPageByName('point')"><img src="@/assets_mobile/img/mypicon.png" /><span>{{ userData.pointAmt ? thousand(userData.pointAmt.toString()) : 0 }}</span></li>
          </ul>
          <a @click="goPageByName('msgMain')" class="mymsg"><img src="@/assets_mobile/img/message_off.svg" /><span class="mgsn">{{ thousand(unReadMessageCount ? unReadMessageCount.toString() : '0') }}</span></a>
        </div>
        <div v-if="!userData">
          <a class="mloginbtn" @click="goPageByName('mobileJoin')">JOIN</a>
          <a class="mloginbtn ml10" @click="goPageByName('mobileLogin')">LOGIN</a>
        </div>
      </div> -->
    </header>
  </template>
</template>

<script>

import { mapState } from 'vuex'
import NewMessageMobile from '@/components/member/MessageMobile.vue'
import SigninMobile from '@/components/member/SigninMobile.vue'
import { getBoardList } from '@/api/board'
export default {
  name: 'Header',
  components: { NewMessageMobile, SigninMobile },
  props: ['type', 'isLoggedIn'],
  data() {
    return {
      isOpenLang: false,
      isLoginOpen: false,
      marqueeList: []
    }
  },
  computed: {
    ...mapState([
      'userData',
      'unReadMessageCount'
    ]),
    isUnReadMessage() {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  watch: {
    userData: {
      handler(value) {
        if(value) {
          this.getMarqueeList();
        }
      },
      immediate: true
    }
  },
  methods: {
    async getMarqueeList() {
      const request = {
        page: 1,
        count_per_list: 5,
        boardType: 'mq',
        startDate: '',
        endDate: '',
        searchValue: ''
      }
      try {
        const response = await getBoardList(request)
        const { resultCode, data, resultMessage } = response.data
        if (resultCode === '200') {
          const marqueeList = []
          data.list.forEach(item => {
            marqueeList.push(item.content)
          });
          this.marqueeList = marqueeList
        } else {
          throw new Error(`Request Failed: ${resultMessage}`)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // moveSignUp() {
    //   this.$router.push('signup')
    // },
    onOpenSignin() {
      this.isLoginOpen = true
    },
    onCloseSignin() {
      this.isLoginOpen = false
    },
    leftSectionToggle() {
      this.$emit('leftSectionToggle')
    },
    rightSectionToggle() {
      this.$emit('rightSectionToggle')
    },
    openLang() {
      this.isOpenLang = !this.isOpenLang
    },
    onLogOut() {
      this.$root.signOut()
      this.$router.push({ name: 'main' })
      // location.reload()
    }
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
