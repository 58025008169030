export const warning = {
  name: 'warning',
  path: '/warning',
  component: () => import('@/views/warning'),
  meta: {
    layout: 'empty',
    text: '점검중',
    gnb: false
  }
}
