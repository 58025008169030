export const give = {
  name: 'give',
  path: '/give',
  component: () => import('@/views_mobile/member/give'),
  meta: {
    layout: 'mobile-default',
    text: '회원머니지급',
    gnb: false
  }
}
