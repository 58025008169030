<template>
  <!-- <div class="right_section" :class="{ active: $props.isActive }">
    <div class="title">
      <img src='@/assets/img/winner_pc/icon/ic_left_logo_2.png' style="width: 120px;" alt="" />
    </div>
    <div class="right_menu">
      <ul>
        <li>
          <i>
            <img src='@/assets_mobile/img/winner_mobile/icon/member/gicon2.png?v=1682318327' /></i>
            <span>{{ userData.memNick }}</span>
            <i class="cursor" @click="getGameCoin"><img src="@/assets/img/winner_pc/main/icon-reload.png" alt="" /></i>
        </li>
        <li>
          <i>
            <img src="@/assets/img/winner_pc/main/money_1.png" alt="" /></i>
          <span>{{ thousand(userData.cashAmt ? userData.cashAmt.toString() : '0') }}</span>
        </li>
        <li>
          <i>
            <img src="@/assets/img/winner_pc/main/point_1.png" alt="" /></i>
          <span>{{ thousand(userData.pointAmt ? userData.pointAmt.toString() : '0') }} P</span>
        </li>
        <li>
          <a @click="openPointExchange()" class="myButton">{{ $t('front.header.pointConversion') }}</a>
        </li>
        <li>
          <a @click="goPageByName('info')" class="myButton_01">{{ $t('front.header.ChangingInfo') }}</a>
        </li>
        <li>
          <button class="myButton_02" @click="signOut">{{ $t('front.common.logout') }}</button>
        </li>
      </ul>
    </div>
  </div> -->
  <div class="popup_mask" v-if="isPointExchageVisible">
    <!-- 포인트 전환 팝업 -->
    <div class="popup1">
      <div class="popup_inner">
        <div class="title">
          <span @click="closePointExchange()"></span>
        </div>
        <div class="signup_logo">
          <img src='@/assets/img/winner_pc/icon/ic_left_logo_2.png' />
        </div>
        <dl>
          <dt>{{ $t('front.cash.nav4') }}</dt>
          <dd>{{ thousand(currentPoint.toString()) }} P</dd>
        </dl>
        <dl>
          <dt>{{ $t('front.point.changePoint') }}</dt>
          <dd>
            <input class="money_input" type="text" pattern="[0-9.,]+" :placeholder="$t('front.cash.inputNumber')" v-model="changePoint" />
          </dd>
        </dl>
        <div class="d-f">
          <button type="button" @click="setPoint('all')">{{ $t('front.cash.all') }}</button>
          <button type="button" @click="onSubmit">{{ $t('front.point.pointTrans') }}</button>
        </div>
        <RouterLink to="point" class="tx">{{ $t('front.mypage.pointChangeList') }}</RouterLink>
        <!-- <RouterLink to="point" class="tx">마이페이지에서 포인트 전환내역확인하기</RouterLink> -->
      </div>
    </div>
  </div>
</template>

<script>
import { thousand } from '@/libs/utils'
import { setChangePoint } from '@/api/point'
import store from '@/store'
// import { mapState } from 'vuex'
export default {
  name: 'NavigationRight',
  props: ['isActive', 'userData'],
  data() {
    return {
      currentPoint: 0,
      changePoint: '',
      isPointExchageVisible: false
    }
  },
  computed: {
    userLevelImage() {
      return require(`../../assets/img/winner_pc/member/icon_level_${this.userData.memLevel}.png`)
    },
    isUnReadMessage() {
      return this.unReadMessageCount && this.unReadMessageCount && this.unReadMessageCount !== '0' && this.$route.name.indexOf('message') === -1 && this.$route.name.indexOf('msg') === -1
    }
  },
  methods: {
    getGameCoin() {
      store.dispatch('storeUserCoinAmt')
    },
    setPoint(value) {
      let point = Number(this.changePoint.replace(/,/g, ''))
      if (value === 'all') {
        point = this.currentPoint
      } else {
        if (value) {
          point += value
        } else {
          point = ''
        }
      }
      console.log(this.currentPoint)

      this.changePoint = thousand(point)
    },
    async onSubmit() {
      if (this.changePoint) {
        if (this.limitMinPoint > this.changePoint) {
          this.onCheck('front.point.limitPoint')
          return false
        }

        const confirm = await this.onConfirm('front.point.confirm')
        if (confirm) {
          const param = {
            pointAmt: Number(this.changePoint.replace(/,/g, ''))
          }

          setChangePoint(param).then(async response => {
            const result = response.data
            if (result.resultCode === '200') {
              const success = await this.onCheck('front.point.success')
              if (success) {
                location.reload()
              }
            }
          })
        }
      } else {
        await this.onCheck('front.point.check')
      }
    },
    openPointExchange() {
      console.log('kkkkkk')
      this.isPointExchageVisible = true
      this.currentPoint = this.userData.pointAmt || 0
    },
    closePointExchange() {
      this.isPointExchageVisible = false
    }
  },
  watch: {
    $route: {
      handler() {
        this.isPointExchageVisible = false
      }
    },
    changePoint() {
      const tempcgPoint1 = Number(this.currentPoint)
      const tempcgPoint2 = Number(this.changePoint.replace(/,/g, ''))
      if (tempcgPoint1 < tempcgPoint2) {
        this.onCheck('front.point.enoughChangePoint')
        this.changePoint = ''
        return false
      } else {
        this.remainPoint = this.currentPoint - Number(this.changePoint.replace(/,/g, ''))

        const parts = this.changePoint.split('.')
        const v = parts[0].replace(/\D/g, '')
        const dec = parts[1]
        const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
        // use this for numeric calculations
        console.log('number for calculations: ', calcNum)
        let n = new Intl.NumberFormat('en-EN').format(v)
        n = dec !== undefined ? n + '.' + dec : n
        this.changePoint = n
      }
    }
  },
  created() {
    // console.log(this.userData)
  }
}
</script>

<!-- <style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/navigation.css"></style>
<style scoped src="@/styles/header.css"></style> -->
