export const gameway = {
  name: 'gameway',
  path: '/gameway',
  component: () => import('@/views/member/gameway'),
  props: true,
  meta: {
    layout: 'default',
    text: '화면구성설명',
    gnb: false
  }
}
