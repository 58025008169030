<template>
  <footer>
    <!-- <img src="@/assets_mobile/img/winner_mobile/img_footer.png" alt="" width="100%" /> -->
    <div class="foot_menu">
      <ul>
        <li>
          <button @click="goPageByName('charge')">
            <img src="@/assets/img/core/plus.png" alt="" />
          </button>
        </li>
        <li>
          <button @click="goPageByName('exchange')">
            <img src="@/assets/img/core/minus.png" alt="" />
          </button>
        </li>
        <li>
          <button href="#wrap" class="home" @click="goPageByName('mobileMain')">
            <img src="@/assets/img/core/goTop.png?v=1" alt="" />
          </button>
        </li>
        <li>
          <!-- todo: 무슨 링크를 걸지 정해서 변경 필요 현재는 문의목록으로 걸려있음 -->
          <button @click="goPageByName('qnaList')">
            <img src="@/assets/img/core/support.png" alt="" />
          </button>
        </li>
        <li>
          <!-- todo: 무슨 링크를 걸지 정해서 변경 필요 현재는 공지사항으로 걸려있음 -->
          <button @click="goPageByName('noticeList')">
            <img src="@/assets/img/core/cont.png" alt="" />
          </button>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer'
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
