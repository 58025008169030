export const bet = {
  name: 'bet',
  path: '/bet',
  component: () => import('@/views_mobile/member/bet'),
  redirect: {
    name: 'betList'
  },
  meta: {
    layout: 'mobile-default',
    text: '베팅리스트',
    gnb: false,
    gnbMenu: 'betList'
  },
  children: [
    {
      name: 'betList',
      path: '/betList',
      component: () => import('@/views_mobile/member/bet/betList'),
      meta: {
        layout: 'mobile-default',
        text: '베팅리스트',
        gnb: false,
        gnbMenu: 'betList'
      },
      redirect: { name: 'sportsBetHistory' },
      children: [
        {
          name: 'casinoBetHistory',
          path: '/betList/casino',
          component: () => import('@/views_mobile/member/bet/betList/casino'),
          meta: {
            groupCode: 'casino',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'slotBetHistory',
          path: '/betList/slot',
          component: () => import('@/views_mobile/member/bet/betList/slot'),
          meta: {
            groupCode: 'slot',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'sportsBetHistory',
          path: '/betList/sports',
          component: () => import('@/views_mobile/member/bet/betList/sports'),
          meta: {
            groupCode: 'sports',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'miniBetHistory',
          path: '/betList/minigame',
          component: () => import('@/views_mobile/member/bet/betList/minigame'),
          meta: {
            groupCode: 'minigame',
            gnbSub: false,
            lnb: true
          }
        }
      ]
    }
  ]
}
