export const minigame = {
  name: 'minigame',
  path: '/minigame',
  component: () => import('@/views_mobile/member/minigame'),
  meta: {
    layout: 'mobile-default',
    text: '미니게임',
    gnb: false
  }
}
