export const slot = {
  name: 'slot',
  path: '/slot',
  component: () => import('@/views_mobile/member/slot'),
  meta: {
    layout: 'mobile-default',
    text: '슬롯',
    gnb: false
  }
}
