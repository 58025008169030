import httpClient from '@/libs/http-client'

export function getBoardList(params) {
  const boardType = params.boardType
  const url = '/api/board/' + boardType + '/list'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBoardDetail(params) {
  const boardType = params.boardType
  const url = '/api/board/' + boardType + '/datail'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function saveBoard(params) {
  const boardType = params.boardType
  const url = '/api/board/' + boardType + '/save'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function saveReply(params) {
  const url = '/api/board/cmtSave'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function removeBoard(params) {
  const url = '/api/board/bbs/remove'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function modifyBoard(params) {
  const url = '/api/board/bbs/modify'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMsgList(params) {
  const url = '/api/board/msgList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getMsgHeadList(params) {
  const url = '/api/board/msgHeadList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function setMsgUpdate(params) {
  const url = '/api/board/msgUpdate'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function setMsgRead(params) {
  const url = '/api/board/msgRead'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
