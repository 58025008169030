export const sports = {
  name: 'sports',
  path: '/sport',
  meta: {
    layout: 'default',
    text: '스포츠',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'liveBetting',
      path: '/livebetting',
      meta: {
        text: '라이브베팅',
        img: require('@/assets/img/menu8.png'),
        gnbSub: true,
        class: 'lebron',
        game: true,
        gameName: 'powerball',
        gameCode: 'sp'
      }
    },
    {
      name: 'combinationBetting',
      path: '/combinationbetting',
      meta: {
        text: '조합베팅',
        img: require('@/assets/img/menu9.png'),
        gnbSub: true,
        class: 'tennis'
      }
    },
    {
      name: 'special',
      path: '/special',
      meta: {
        text: '스페셜',
        img: require('@/assets/img/menu10.png'),
        gnbSub: true,
        class: 'messi'
      }
    }
  ]
}
