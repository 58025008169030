export const slotGame = {
  name: 'slotGame',
  path: '/slotgame',
  meta: {
    layout: 'default',
    text: '슬롯게임',
    gnb: true,
    sub: true
  },
  children: [
    {
      name: 'kplayslot',
      path: '/kplayslot',
      meta: {
        text: '케이플레이 슬롯',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 0,
        class: 'kslot'
      }
    },
    {
      name: 'pragslot',
      path: '/pragslot',
      meta: {
        text: '프라그마틱플레이슬롯',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 200,
        class: 'kslot1'
      }
    },
    {
      name: 'habanero',
      path: '/habanero',
      meta: {
        text: '하바네로',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 201,
        class: 'kslot2'
      }
    },
    {
      name: 'elle',
      path: '/slot_elle',
      meta: {
        text: '엘리시움',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 202,
        class: 'kslot3'
      }
    },
    {
      name: 'quick',
      path: '/slot_quick',
      meta: {
        text: '퀵스핀',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 204,
        class: 'kslot4'
      }
    },
    {
      name: 'spade',
      path: '/slot_spade',
      meta: {
        text: '스페이드 게이밍',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 205,
        class: 'kslot5'
      }
    },
    {
      name: 'playandgo',
      path: '/slot_playandgo',
      meta: {
        text: '플레이 앤 고',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 207,
        class: 'kslot6'
      }
    },
    {
      name: 'worldmatch',
      path: '/slot_worldmath',
      meta: {
        text: '월드매치',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 208,
        class: 'kslot7'
      }
    },
    {
      name: 'slot_microgaming',
      path: '/slot_microgaming',
      meta: {
        text: '마이크 게이밍 슬롯',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 209,
        class: 'kslot8'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: 'YL 게이',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 212,
        class: 'kslot9'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: 'Evo 레드타이거',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 213,
        class: 'kslot10'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '넷엔트',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 214,
        class: 'kslot11'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: 'YGG 드라실',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 216,
        class: 'kslot12'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '드레이곤 소프트',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 215,
        class: 'kslot13'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '분고',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 217,
        class: 'kslot14'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '플레이슨',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 218,
        class: 'kslot15'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '플레이테크 슬롯',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 219,
        class: 'kslot16'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: 'CQ9 슬롯',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 220,
        class: 'kslot17'
      }
    },
    {
      name: 'slot_ylgaming',
      path: '/slot_ylgaming',
      meta: {
        text: '스카이윈드',
        img: require('@/assets/img/menu7.png'),
        gnbSub: true,
        game: true,
        gameName: 'slot',
        gameCode: 221,
        class: 'kslot18'
      }
    }
  ]
}
