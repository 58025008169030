export const board = {
  name: 'board',
  path: '/board',
  component: () => import('@/views/member/board/'),
  redirect: {
    name: 'noticelist'
  },
  meta: {
    layout: 'default',
    text: '게시판',
    gnb: true,
    sub: false
  },
  children: [
    {
      name: 'noticelist',
      path: '/board/list',
      component: () => import('@/views/member/board/list'),
      meta: {
        gnbSub: false
      }
    },
    {
      name: 'boardRead',
      path: '/board/read/:boardIdx',
      component: () => import('@/views/member/board/read'),
      meta: {
        gnbSub: false
      }
    },
    {
      name: 'boardWrite',
      path: '/board/write',
      component: () => import('@/views/member/board/write'),
      meta: {
        gnbSub: false
      }
    }
  ]
}
