export const mypage = {
  name: 'mypage',
  path: '/mypage',
  component: () => import('@/views/member/mypage'),
  redirect: {
    name: 'bettingList'
  },
  meta: {
    layout: 'default',
    text: '마이페이지',
    gnb: false
  },
  children: [
    {
      name: 'bettingList',
      path: 'betting/list',
      component: () => import('@/views/member/mypage/bet/bettingList'),
      meta: {
        text: 'front.mypage.betList',
        lnbClass: 'csmenu6',
        gnbSub: true,
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'chargeExchangeList',
      path: '',
      component: () => import('@/views/member/mypage/chargeExchange/'),
      redirect: {
        name: 'chargeList'
      },
      meta: {
        text: 'front.mypage.depositWithdrawalList',
        lnbClass: 'csmenu7',
        gnbSub: true,
        lnb: true,
        lnbChildren: true
      },
      children: [
        {
          name: 'chargeList',
          path: 'charge/list',
          component: () => import('@/views/member/mypage/chargeExchange/chargeList'),
          meta: {
            text: 'front.mypage.depositList',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'exchangeList',
          path: 'exchange/list',
          component: () => import('@/views/member/mypage/chargeExchange/exchangeList'),
          meta: {
            text: 'front.mypage.withdrawalList',
            gnbSub: false,
            lnb: true
          }
        }
      ]
    },
    {
      name: 'stributor_mypage',
      path: 'stributor_mypage',
      redirect: {
        name: 'stributor'
      },
      meta: {
        text: 'front.mypage.exclusive',
        lnbClass: 'csmenu8',
        gnbSub: true,
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'winlose',
      path: 'winlose',
      component: () => import('@/views/member/mypage/winlose'),
      meta: {
        text: '원루즈정산',
        lnbClass: 'csmenu9',
        gnbSub: false,
        lnb: false,
        lnbChildren: false
      }
    },
    {
      name: 'coupon',
      path: 'coupon',
      component: () => import('@/views/member/mypage/coupon'),
      redirect: {
        name: 'couponUse'
      },
      meta: {
        text: '쿠폰관리',
        lnbClass: 'csmenu10',
        gnbSub: false,
        lnb: false,
        lnbChildren: false
      },
      children: [
        {
          name: 'couponUse',
          path: 'use',
          component: () => import('@/views/member/mypage/coupon/use'),
          meta: {
            text: '쿠폰사용',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'couponGift',
          path: 'gift',
          component: () => import('@/views/member/mypage/coupon/gift'),
          meta: {
            text: '쿠폰선물',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'couponList',
          path: 'list',
          component: () => import('@/views/member/mypage/coupon/list'),
          meta: {
            text: '쿠폰내역',
            gnbSub: false,
            lnb: true
          }
        }
      ]
    },
    {
      name: 'point',
      path: 'point',
      component: () => import('@/views/member/mypage/point'),
      redirect: {
        name: 'pointUse'
      },
      meta: {
        text: 'front.mypage.pointList',
        lnbClass: 'csmenu11',
        gnbSub: true,
        lnb: true,
        lnbChildren: true
      },
      children: [
        {
          name: 'pointUse',
          path: 'use',
          component: () => import('@/views/member/mypage/point/use'),
          meta: {
            text: 'front.mypage.pointChange',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'pointList',
          path: 'list',
          component: () => import('@/views/member/mypage/point/list'),
          meta: {
            text: 'front.mypage.pointSave',
            gnbSub: false,
            lnb: true
          }
        },
        {
          name: 'couponChange',
          path: 'change',
          component: () => import('@/views/member/mypage/point/uselist'),
          meta: {
            text: 'front.mypage.pointChangeList',
            gnbSub: false,
            lnb: true
          }
        }
      ]
    },
    {
      name: 'message',
      path: 'message',
      component: () => import('@/views/member/mypage/message'),
      redirect: {
        name: 'messageList'
      },
      meta: {
        text: 'front.mypage.message',
        lnbClass: 'csmenu12',
        gnbSub: true,
        lnb: true,
        lnbChildren: false
      },
      children: [
        {
          name: 'messageList',
          path: 'list',
          component: () => import('@/views/member/mypage/message/list'),
          meta: {
            text: '쪽지리스트',
            gnbSub: false,
            lnb: false
          }
        },
        {
          name: 'messageRead',
          path: 'read',
          component: () => import('@/views/member/mypage/message/read'),
          props: true,
          meta: {
            text: '쪽지읽음',
            gnbSub: false,
            lnb: false
          }
        }
      ]
    },
    {
      name: 'info',
      path: 'info',
      component: () => import('@/views/member/mypage/info'),
      meta: {
        text: 'front.mypage.memInfoModify',
        lnbClass: 'csmenu13',
        gnbSub: true,
        lnb: true,
        lnbChildren: false
      }
    },
    {
      name: 'give',
      path: 'give',
      component: () => import('@/views/member/mypage/give'),
      meta: {
        text: 'front.mypage.memMoney',
        lnbClass: 'csmenu19',
        gnbSub: true,
        lnb: true,
        lnbChildren: false
      }
    }
  ]
}
