export const casino = {
  name: 'casino',
  path: '/casino',
  component: () => import('@/views_mobile/member/casino'),
  meta: {
    layout: 'mobile-default',
    text: '라이브카지노',
    gnb: false
  }
}
