import httpClient from '@/libs/http-client'

export function setChangePoint(params) {
  const url = '/api/cash/pointChg'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getPointList(params) {
  const url = '/api/cash/pointList'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
