<template>
  <div class="slotmodal" v-if="list && list.length > 0">
    <h3>{{$t('front.gnb.slotgame')}}<img class="close" src="@/assets/img/icon_cancel.png" @click="$emit('isSlot')" /></h3>
    <div class="slotmodalwrap">
        <ul>
          <template v-for="(item, index) in list" v-bind:key="index">
             <template v-for="subItem in item" v-bind:key="subItem.id">
               <li>
                 <a @click="setSlotGame(subItem)">
                   <img :src="subItem.image" />
                   <span class="sname">{{subItem.names.ko}}</span>
                 </a>
               </li>
             </template>
          </template>
        </ul>
    </div>
  </div>
</template>

<script>
import { getGameSlotList } from '@/api/game'
import { mapState } from 'vuex'

export default {
  name: 'SlotList',
  props: [
    'groupCode',
    'codeName',
    'code'
  ],
  data() {
    return {
      list: []
    }
  },
  created() {
    if (this.groupCode && this.codeName) {
      this.loadSlotList()
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  watch: {
    codeName() {
      if (this.groupCode && this.codeName) {
        this.loadSlotList()
      }
    }
  },
  methods: {
    async setSlotGame(item) {
      const gameKey = item.key
      const codeInfo = this.code ? this.commonCodeByCode[this.code] : this.commonCode[this.groupCode][this.codeName]
      const params = {
        vendorKey: codeInfo.code.toString(),
        gameType: codeInfo.groupCode.toString(),
        gameKey: gameKey
      }
      console.log(params)
      if (this.userData) {
        if (codeInfo.codeName.indexOf('H-') > -1) {
          // hotel
          await this.onSlotSelectGame(params)
        } else {
          await this.onGetGame(params)
        }
      } else {
        await this.onCheck('front.error.afterSignin')
      }
    },
    async loadSlotList() {
      this.emitter.emit('Loading', true)
      const params = await this.onSelectGame(this.groupCode, this.codeName)

      if (this.codeName.indexOf('H-') > -1) {
        const subList = [
          {
            category: 'slot',
            key: 'SLOT01',
            image: require('@/assets/img/game_h-slot_pc_1.jpg')
          },
          {
            category: 'slot',
            key: 'SLOT02',
            image: require('@/assets/img/game_h-slot_pc_2.jpg')
          }
        ]
        this.list.push(subList)
      } else {
        getGameSlotList(params).then(response => {
          const result = response.data
          if (result.resultCode === '200') {
            const data = result.data
            const list = data.games

            let subList = []
            list.forEach((item, index) => {
              subList.push(item)
              if (list.length > 4) {
                if ((index + 1) % 4 === 0) {
                  this.list.push(subList)
                  subList = []
                }
              } else {
                this.list.push(subList)
              }
            })
          } else {
            alert(result.resultMessage)
          }
        })
      }
      this.emitter.emit('Loading', false)
    }
  }
}
</script>

<style scoped>
.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #141414;border: 1px solid #333;border-radius: 30px;position: fixed;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 20;width: 1314px;}
.slotmodal>h3 {width: 100%;
  position: relative;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  padding: 12px 0;
  background: #000;
  border-radius: 15px 15px 0 0;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 4px;height: 40px;}
.slotmodalwrap {max-height: 680px;overflow-y: auto;padding-top: 30px;}
.slotmodalwrap ul {padding: 0 30px 30px;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;flex: auto;max-width: 190px;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width:100%; border-radius: 10px;border: 2px solid #555;height: 160px;object-fit: cover;object-position: center;}
.sname {font-size: 20px;color: #fff;margin-top: 5px;line-height: 1.2em;display: flex;align-items: center;justify-content: center;}
</style>
<style>
@media (min-width: 1px) and (max-width: 1314px){
   .slotmodal {width: 100% !important;}
}
</style>
