export const coupon = {
  name: 'couponMain',
  path: '/couponMain',
  component: () => import('@/views_mobile/member/coupon'),
  redirect: {
    name: 'coupon'
  },
  meta: {
    layout: 'mobile-default',
    text: '쿠폰',
    gnb: false
  },
  children: [
    {
      name: 'coupon',
      path: '/coupon',
      component: () => import('@/views_mobile/member/coupon/coupon'),
      meta: {
        layout: 'mobile-default',
        text: '쿠폰',
        gnb: false
      }
    }
  ]
}
