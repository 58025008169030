import httpClient from '@/libs/http-client'

export function signIn(params) {
  const url = '/api/member/login'
  // params.password = encrypt(params.password, params.username)

  // 공통 httpClient 객체를 만들어 사용
  // httpClient 참고

  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getBankList(params) {
  const url = '/api/etc/bank'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function signUp(params) {
  const url = '/api/member/signup'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function getUserInfo() {
  const url = '/api/member/info'
  return httpClient.post(url, {}).then(response => {
    return response
  })
}

export function getToken() {
  const url = '/api/getToken'
  return httpClient.post(url, {}).then(response => {
    return response
  })
}

export function signOut(params) {
  const url = '/api/member/logout'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function memberUpdate(params) {
  const url = '/api/member/update'
  return httpClient.post(url, params).then(response => {
    return response
  })
}

export function isPlayGame() {
  const url = '/api/kgon/isPlayGame'
  return httpClient.post(url, {}).then(response => {
    return response
  })
}
