export const live = {
  name: 'live',
  path: '/live',
  meta: {
    layout: 'default',
    text: '라이브영상',
    gnb: true,
    sub: false
  }
}
