export const stributor = {
  name: 'stributor',
  path: '/stributor',
  component: () => import('@/views_mobile/member/stributor'),
  meta: {
    layout: 'mobile-default',
    text: '총판페이지',
    gnb: false
  }
}
