export const cscenter = {
  name: 'csMain',
  path: '/csMain',
  component: () => import('@/views_mobile/member/cs'),
  redirect: {
    name: 'qnaList'
  },
  meta: {
    layout: 'mobile-default',
    text: 'cs',
    gnb: false,
    gnbMenu: 'cs'
  },
  children: [
    {
      name: 'qnaList',
      path: '/qnaList',
      component: () => import('@/views_mobile/member/cs/qna/list'),
      meta: {
        layout: 'mobile-default',
        text: '문의하기',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'qnaRead',
      path: '/qna/read/:boardIdx',
      component: () => import('@/views_mobile/member/cs/qna/read'),
      meta: {
        layout: 'mobile-default',
        text: '문의하기',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'qnaWrite',
      path: '/qnaWrite',
      component: () => import('@/views_mobile/member/cs/qna/write'),
      meta: {
        layout: 'mobile-default',
        text: '문의하기',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'noticeList',
      path: '/noticeList',
      component: () => import('@/views_mobile/member/cs/notice/list'),
      meta: {
        layout: 'mobile-default',
        text: '공지사항',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'noticeRead',
      path: '/notice/read/:boardIdx',
      component: () => import('@/views_mobile/member/cs/notice/read'),
      meta: {
        layout: 'mobile-default',
        text: '공지사항',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'eventList',
      path: '/eventList',
      component: () => import('@/views_mobile/member/cs/event/list'),
      meta: {
        layout: 'mobile-default',
        text: '공지사항',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'eventRead',
      path: '/event/read/:boardIdx',
      component: () => import('@/views_mobile/member/cs/event/read'),
      meta: {
        layout: 'mobile-default',
        text: '공지사항',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'faq',
      path: '/faq',
      component: () => import('@/views_mobile/member/cs/faq/faq'),
      meta: {
        layout: 'mobile-default',
        text: '자주 묻는 질문',
        gnb: false,
        gnbMenu: 'cs'
      }
    },
    {
      name: 'rule',
      path: '/rule',
      component: () => import('@/views_mobile/member/cs/rule/rule'),
      meta: {
        layout: 'mobile-default',
        text: '베팅규정',
        gnb: false,
        gnbMenu: 'cs'
      }
    }
  ]
}
