import { createApp } from 'vue'
import router from './router/index'
import routerMobile from './router_mobile/index'
import store from './store'
import App from './App.vue'
import vfmPlugin from 'vue-final-modal'
import { isMobile } from '@/libs/utils'
import CommonMixin from '@/plugins/common'

import { createI18n } from 'vue-i18n'
import i18nMessage from '@/data/i18n'
import mitt from 'mitt'
import { getLangCookie, setLangCookie, setRunMode, getRunMode } from '@/libs/auth-helper'
import Vue3Marquee from 'vue3-marquee'
import '@/styles/fontAwsome.css'
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import CKEditor from '@ckeditor/ckeditor5-vue';
const mode = getRunMode()
const modeDefault = {
  isDev: false,
  siteId: '',
  apiKey: ''
}

if (!mode) {
  setRunMode(JSON.stringify(modeDefault))
} else {
  setRunMode(JSON.stringify(mode))
}
let lang = getLangCookie()

if (!lang) {
  lang = 'ko'
  setLangCookie(lang)
}
document.body.classList = lang

const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false, // you must set `false`, to use Composition API
  locale: lang, // set locale
  fallbackLocale: 'en', // set fallback locale
  globalInjection: true,
  messages: i18nMessage.messages // set locale messages
})

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.use(store)
  .use(isMobile() ? routerMobile : router)
  .use(Vue3Marquee)
  .use(CKEditor)
  .use(vfmPlugin).use(i18n).mixin(CommonMixin)
  .mount('body')
